<template>
  <div
    class="mb-2 py-1 pl-0.5 flex flex-wrap lg:flex-nowrap lg:items-stretch gap-y-1 justify-between border border-gray-200 dark:border-gray-700 rounded shadow"
  >
    <div class="order-first hidden lg:block w-10 text-base self-center px-1">{{ lp }}</div>
    <div class="w-2/3 lg:w-1/3 pr-5">
      <input v-if="edited.name" @keyup.enter="save" @keydown.esc="cancel" @blur="cancel" class="w-full" type="text" v-model="insurerCache.name" ref="name">
      <button v-else @click="edit('name')"
        class="w-full text-left px-2 py-0.5 border dark:border-gray-600 hover:bg-blue-200 dark:hover:bg-blue-900 rounded"
        title="Kliknij, aby edytować"
      >{{ insurer.name }}</button>
      <p v-if="saveErrors.name != undefined" class="help is-danger">{{ saveErrors.name }}</p>
    </div>
    <div class="order-last lg:order-1 w-full flex-grow-0 lg:w-auto lg:flex-grow lg:min-w-0 pr-1">
      <input v-if="edited.full_name" @keyup.enter="save" @keydown.esc="cancel" @blur="cancel" class="w-full" type="text" v-model="insurerCache.full_name" ref="full_name">
      <button v-else @click="edit('full_name')"
        class="w-full text-left px-2 py-0.5 border dark:border-gray-600 hover:bg-blue-200 dark:hover:bg-blue-900 rounded"
        title="Kliknij, aby edytować"
      >{{ insurer.full_name }}</button>
      <!-- <input v-else @click="edit('full_name')" class="input is-small" :value="insurer.full_name" readonly="true" :title="insurer.full_name"> -->
      <p v-if="saveErrors.full_name != undefined" class="help is-danger">{{ saveErrors.full_name }}</p>
    </div>
    <div class="order-2 w-14 flex gap-x-1 px-1">
      <button @click="$emit('editEmployees', insurer)" title="Przypisz agentów" class="tw--btn-icon p-0"><FontAwesomeIcon icon="user-friends" size="lg" /></button>
      <button @click="$emit('delInsurer', insurer)" title="Usuń TU" class="tw--btn-icon tw--btn-icon-danger p-0"><FontAwesomeIcon :icon="['far', 'trash-alt']" size="lg" /></button>
      <a><i class="far fa-trash-alt has-text-danger" @click="$emit('delInsurer', insurer)" title="Usuń TU"></i></a>
    </div>
  </div>
</template>

<script>
import InsurerService from '../../services/InsurerService';
import { confirm } from '../../mixins/confirm';
import { errorHandler } from '../../mixins/errorHandler';

export default {
  name: 'InsurerListItem',
  mixins: [confirm, errorHandler],
  props: {
    insurer: {
      type: Object,
      required: true,
    },
    lp: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      edited: {
        name: false,
        full_name: false,
      },
      saveErrors: {},
      insurerCache: {},
    };
  },
  methods: {
    edit(field) {
      this.insurerCache[field] = this.insurer[field];
      this.edited[field] = true;
      this.$nextTick(() => {
        console.log(this.$refs[field]);
        this.$refs[field].select();
        this.$refs[field].focus();
      });
      this.edited[field] = true;
    },
    cancel() {
      Object.keys(this.edited).forEach((key) => { this.edited[key] = false; });
      this.saveErrors = {};
    },
    save() {
      if (this.insurerCache.name !== undefined && this.insurerCache.name !== this.insurer.name) {
        this.confirm(() => this.confirmedSave(), 'Zmiana spowoduje wyświetlanie nowej nazwy TU w całej aplikacji (produkty, karta polisy itp). Potwierdzasz zapisanie zmiany?');
      } else if (this.insurerCache.full_name !== undefined && this.insurerCache.full_name !== this.insurer.full_name) {
        this.confirmedSave();
      } else {
        this.cancel();
      }
    },
    confirmedSave() {
      this.saveErrors = {};
      InsurerService.updateInsurer(this.insurer.id, this.insurerCache)
        .then(response => {
          Object.keys(this.edited).forEach((key) => { this.edited[key] = false; });
          this.insurerCache = {};
          this.$emit('insurerUpdated', response.data.result);
        })
        .catch(error => {
          this.saveErrors = this.resolveError(error);
        });
    },
  },
};
</script>
