import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  // withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
  },
});

export default {
  addInsurer(formData) {
    const result = new Promise((resolve, reject) => {
      apiClient.post('/api/insurer', formData)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  deleteInsurer(id) {
    const result = new Promise((resolve, reject) => {
      apiClient.delete(`/api/insurer/${id}`)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  getInsurerEmployees(id) {
    const result = new Promise((resolve, reject) => {
      apiClient.get(`/api/insurer/${id}/employees`)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  addInsurerEmployees(insurerId, employeeIds) {
    const result = new Promise((resolve, reject) => {
      apiClient.put(`/api/insurer/${insurerId}/employees`, employeeIds)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  updateInsurer(id, fields) {
    const result = new Promise((resolve, reject) => {
      apiClient.patch(`/api/insurer/${id}/update`, fields)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
};