<template>
  <BaseCard>
    <button @click="addInsurerModal = true" class="tw--btn max-w-max"><FontAwesomeIcon :icon="['far', 'building']" />&nbsp;Dodaj TU</button>

    <div class="w-full 2xl:w-1/2">

      <div class="hidden lg:flex font-semibold">
        <div class="w-10">lp</div>
        <div class="w-1/3">nazwa skrócona</div>
        <div class="flex-grow">nazwa pełna</div>
        <div class="w-14"></div>
      </div>

      <div>
        <InsurerListItem
          v-for="(insurer, index) in insurers"
          :insurer="insurer"
          :lp="index + 1"
          :key="insurer.id"
          @editEmployees="editEmployees"
          @delInsurer="confirmDelInsurer"
          @insurerUpdated="insurerUpdated"
        />
      </div>

    </div>

    <BaseModal v-if="addInsurerModal"
      @closeModal="cancelAddInsurer"
      :cancel="true"
      :escCancel="true"
      :ok="true"
      :busy="saving"
      okLabel="zapisz"
      @ok="saveNewInsurer"
      size="md"
    >
      <template #header>
        Dodawanie nowego TU
      </template>
      <template #default>
        <div class="flex flex-col gap-y-2">
          <BaseInputWithAddon
            type="text"
            labelTop="nazwa skrócona *"
            v-model="newInsurer.name"
            :error="saveErrors.name !== undefined ? saveErrors.name : ''"
          />
          <BaseInputWithAddon
            type="text"
            labelTop="nazwa pełna **"
            v-model="newInsurer.full_name"
            :error="saveErrors.full_name !== undefined ? saveErrors.full_name : ''"
          />
          <div class="text-muted text-sm italic">
            <div>* pole obowiązkowe (nazwa wyświetlana w aplikacji)</div>
            <div>
              ** pole służy aplikacji do wypełniania w pismach (np. wypowiedzenie), więc powinna być to oficjalna nazwa TU.
              Jeśli pole będzie pozostawione puste, zostanie automatycznie uzupełnione identycznie jak wartość pola [nazwa skrócona]
            </div>
          </div>
        </div>
      </template>
    </BaseModal>

    <BaseModal v-if="editEmployeesModal"
      @closeModal="cancelEmployees"
      :cancel="true"
      :escCancel="true"
      :ok="true"
      :busy="saving"
      okLabel="zapisz"
      @ok="saveEmployees"
      size="xl"
    >
      <template #header>
        <strong>{{ editEmployeesInsurer.name }}</strong>: edycja uprawnień KNF
      </template>
      <template #default>
        <div class="flex flex-col sm:flex-row flex-wrap gap-x-3 gap-y-1 text-muted">

          <div class="flex-1 p-3 bg-white dark:bg-gray-800 rounded shadow-xl">
            <div class="flex items-center -mt-3 mb-1 -mx-3 px-3 py-1 border-b dark:border-gray-700">
              <h1 class="uppercase font-semibold">przypisani</h1>
            </div>
            <div class="flex flex-col gap-y-1">
              <div v-for="employee in newOwnedEmployeeList" :key="employee.id" class="flex gap-x-2 px-2 bg-gray-100 dark:bg-gray-900 rounded-sm">
                <span class="flex-grow">{{ employee.fullname }}</span>
                <a href="#" @click.prevent="removeEmployee(employee.id)" class="text-red-400 dark:text-red-900 hover:text-red-300 dark:hover:text-red-700" title="usuń"><FontAwesomeIcon icon="times" /></a>
              </div>
            </div>
          </div>

          <div class="flex-1 p-3 bg-white dark:bg-gray-800 rounded shadow-xl">
            <div class="flex items-center -mt-3 mb-1 -mx-3 px-3 py-1 border-b dark:border-gray-700">
              <h1 class="uppercase font-semibold">nieprzypisani</h1>
            </div>
            <div class="flex flex-col gap-y-1">
              <div v-for="aEmployee in availableEmployeeList" :key="aEmployee.id" class="flex gap-x-2 pl-1 bg-gray-100 dark:bg-gray-900 rounded-sm">
                <a href="#" @click.prevent="addEmployee(aEmployee.id)"  class="text-blue-400 dark:text-blue-900 hover:text-blue-300 dark:hover:text-blue-700" title="dodaj"><FontAwesomeIcon icon="plus" /></a>
                <span>{{ aEmployee.fullname }}</span>
              </div>
            </div>
          </div>

        </div>
      </template>
    </BaseModal>

    <div v-if="false" class="modal" :class="{'is-active': editEmployeesModal}">
      <div class="modal-background"></div>
      <div class="modal-card" style="position: relative">
        <header class="modal-card-head">
          <p class="modal-card-title is-size-6"><strong>{{ editEmployeesInsurer.name }}</strong>: edycja uprawnień KNF</p>
          <button @click="cancelEmployees" class="delete" aria-label="close"></button>
        </header>
        <section class="modal-card-body">
          <div class="tile is-ancestor">
            <div class="tile is-parent">
              <div class="tile is-child box">
                <h1 class="is-size-6">przypisani</h1>
                <div v-for="employee in newOwnedEmployeeList" :key="employee.id" class="list__element">
                  <div class="tags has-addons">
                    <a @click="removeEmployee(employee.id)" class="tag is-delete"></a>
                    <span class="tag">{{ employee.fullname }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="tile is-parent">
              <div class="tile is-child box">
                <h1 class="is-size-6">nieprzypisani</h1>
                <div v-for="aEmployee in availableEmployeeList" :key="aEmployee.id" class="list__element">
                  <div class="tags has-addons">
                    <a @click="addEmployee(aEmployee.id)" class="tag"><i class="fas fa-plus"></i></a>
                    <span class="tag">{{ aEmployee.fullname }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot" style="justify-content: flex-end">
          <button @click="cancelEmployees" class="button is-small">Anuluj</button>
          <button @click="saveEmployees" class="button is-primary is-small" :class="{'is-loading': saving}" :disabled="saveNotAvailable">Zapisz</button>
        </footer>
      </div>
    </div>
  </BaseCard>
</template>

<script>
import { confirm } from '../../mixins/confirm';
import { errorHandler } from '../../mixins/errorHandler';
import InsurerListItem from './InsurerListItem.vue';
import InsurerService from '../../services/InsurerService';

export default {
  name: 'Insurers',
  components: { InsurerListItem },
  mixins: [confirm, errorHandler],
  data() {
    return {
      newInsurer: {
        name: '',
        full_name: '',
      },
      insurerEmployeeList: [],
      newOwnedEmployeeIds: [],
      editEmployeesInsurer: {},
      editEmployeesModal: false,
      addInsurerModal: false,
      saving: false,
      saveErrors: {},
    };
  },
  computed: {
    insurers() {
      return this.$store.getters['products/insurerList'];
    },
    employeeList() {
      return this.$store.state.employees.employeeSimpleList;
    },
    availableEmployeeList() {
      return this.employeeList.filter(el => !this.newOwnedEmployeeIds.includes(el.id));
    },
    newOwnedEmployeeList() {
      return this.employeeList.filter(el => this.newOwnedEmployeeIds.includes(el.id));
    },
    ownedEmployeeIds() {
      return this.insurerEmployeeList.map(el => el.id).sort((a, b) => Number(a) - Number(b));
    },
    saveNotAvailable() {
      return JSON.stringify(this.ownedEmployeeIds) === JSON.stringify(this.newOwnedEmployeeIds);
    },
  },
  created() {
    this.$store.dispatch('products/getInsurerList').catch(error => this.resolveError(error));
    this.$store.dispatch('employees/getEmployeeSipleList').catch(error => this.resolveError(error));
  },
  methods: {
    addInsurer() {
      this.addInsurerModal = true;
    },
    saveNewInsurer() {
      this.saving = true;
      const formData = new FormData();
      formData.append('name', this.newInsurer.name);
      formData.append('full_name', this.newInsurer.full_name);
      InsurerService.addInsurer(formData)
        .then(() => {
          this.saving = false;
          this.insurerUpdated();
          this.cancelAddInsurer();
        })
        .catch(error => {
          this.saving = false;
          this.saveErrors = this.resolveError(error);
        });
    },
    cancelAddInsurer() {
      this.addInsurerModal = false;
      this.newInsurer.name = '';
      this.newInsurer.full_name = '';
      this.saveErrors = {};
    },
    confirmDelInsurer(insurer) {
      this.confirm(() => this.delInsurer(insurer), 'Czy na pewno usunąć Towarzystwo Ubezpieczniowe?');
    },
    delInsurer(insurer) {
      this.saving = true;
      InsurerService.deleteInsurer(insurer.id)
        .then(() => {
          this.saving = false;
          this.insurerUpdated();
        })
        .catch(error => {
          this.saving = false;
          this.resolveError(error);
        });
    },
    editEmployees(insurer) {
      this.saving = true;
      this.editEmployeesModal = true;
      this.editEmployeesInsurer = insurer;
      InsurerService.getInsurerEmployees(insurer.id)
        .then(response => {
          this.insurerEmployeeList = response.data.result;
          this.newOwnedEmployeeIds = this.insurerEmployeeList.map(el => el.id).sort((a, b) => Number(a) - Number(b));
          this.saving = false;
        })
        .catch(error => {
          this.saving = false;
          this.resolveError(error);
        });
    },
    cancelEmployees() {
      this.editEmployeesModal = false;
      this.insurerEmployeeList = [];
      this.editEmployeesInsurer = {};
      this.newOwnedEmployeeIds = [];
    },
    addEmployee(id) {
      this.newOwnedEmployeeIds.push(id);
      this.newOwnedEmployeeIds.sort((a, b) => Number(a) - Number(b));
    },
    removeEmployee(id) {
      this.newOwnedEmployeeIds = this.newOwnedEmployeeIds.filter(el => el !== id);
    },
    saveEmployees() {
      this.saving = true;
      InsurerService.addInsurerEmployees(this.editEmployeesInsurer.id, { ids: this.newOwnedEmployeeIds })
        .then(response => {
          this.employeeInsurers = response.data.result;
          this.saving = false;
          this.editEmployeesModal = false;
        })
        .catch((error) => {
          this.saving = false;
          this.saveErrors = this.resolveError(error);
        });
    },
    insurerUpdated() {
      this.$store.dispatch('products/getInsurerList').catch(error => this.resolveError(error));
    },
  },
};
</script>
